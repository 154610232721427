import "core-js/modules/es6.regexp.replace";
export var getByTypeName = function getByTypeName(data, typename) {
  return data.filter(function (d) {
    return d.__typename === typename;
  })[0];
};
export var isHeroSlice = function isHeroSlice(type) {
  return type.type === "hero";
};
export var isBigBulletSlice = function isBigBulletSlice(type) {
  return type.type === "big_bullets";
};
export var isImageFeatureBlock = function isImageFeatureBlock(type) {
  return type.type === "image_feature_block";
};
export var isCardLayout = function isCardLayout(type) {
  return type.type === "cards";
};
export var isFullCaseStudy = function isFullCaseStudy(type) {
  return type.type === "full_case_study";
};
export var isPeopleLayout = function isPeopleLayout(type) {
  return type.type === "people";
};
export var isCaseStudy = function isCaseStudy(type) {
  return type.type === "case_study";
};
export var isExpanders = function isExpanders(type) {
  return type.type === "expanders";
};
export var handleLineBreaks = function handleLineBreaks(content) {
  if (!content) {
    return null;
  }

  return content.replace(/\u2028/g, '\r');
};